<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <notifications></notifications>
      <login-card header-color="orange">
        <h4 slot="title" class="title">
          Welcome To Avalia Assess
        </h4>
        Please fill in this form to finalize your invitation to the platform.
        <md-field slot="inputs" class="md-form-group">
          <label>First name...</label>
          <md-input
            v-model="firstName"
            type="text"
            required
            @keydown.enter="acceptInvitation"
          />
        </md-field>
        <md-field slot="inputs" class="md-form-group">
          <label>Last name...</label>
          <md-input
            v-model="lastName"
            type="text"
            required
            @keydown.enter="acceptInvitation"
          />
        </md-field>
        <md-field slot="inputs" class="md-form-group">
          <label>Password...</label>
          <md-input
            v-model="password"
            type="password"
            required
            @keydown.enter="acceptInvitation"
          />
        </md-field>
        <md-field slot="inputs" class="md-form-group">
          <label>Password...</label>
          <md-input
            v-model="secondPassword"
            type="password"
            @keydown.enter="acceptInvitation"
          />
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-info md-lg"
          @click.native="acceptInvitation"
        >
          Connect
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components/Template';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
// import Vue from 'vue';

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      invitationId: null,
      invitationToken: null,
      firstName: null,
      lastName: null,
      password: null,
      secondPassword: null,
    };
  },
  methods: {
    acceptInvitation() {
      if (this.$route.params.id === null) {
        const errorMessage = 'First name cannot be null';
        notify.notifyError(errorMessage, this);
        return;
      }

      if (this.firstName == null || this.firstName === '') {
        const errorMessage = 'First name cannot be null';
        notify.notifyError(errorMessage, this);
        return;
      }
      if (this.lastName == null || this.lastName === '') {
        const errorMessage = 'Last name cannot be null';
        notify.notifyError(errorMessage, this);
        return;
      }
      if (this.password == null || this.password.length <= 7) {
        const errorMessage = 'Password must be at least 8 character long';
        notify.notifyError(errorMessage, this);
        return;
      }
      if (this.secondPassword == null || !this.password === this.secondPassword) {
        const errorMessage = 'Both password must match';
        notify.notifyError(errorMessage, this);
        return;
      }

      api.idm
        .post('/api/public/auth', {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.errorMessage = '';
          this.$store.dispatch('users/login', response.data.token);
          this.$router.push('/projects');
        })
        .catch((error) => {
          const errorMessage = `Authentication failed : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.google-button {
  justify-content: center;
  display: flex;
}

</style>>
